import React, { lazy, Suspense, useState, useEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { ContentRoute, LayoutSplashScreen } from "../_metronic/layout";
import PaymentDelivery from "./modules/Payment";
import HelpCenter from "./modules/Auth/pages/HelpCenter";
import GuidedTour from "./modules/Settings/DriverJs/IntroDriver";
import PharmacyDetails from "./modules/ECommerce/pages/manageorders/pharmacy-details/PharmacyDetails";
import ProductOTC from "./modules/ECommerce/pages/ProductsOTC";
import ProductOTCEvent from "./modules/ECommerce/pages/AddProductOTC";
import TransactionManagement from "./modules/ECommerce/pages/AccountsPayable/TransactionManagement";
import { StaffAssignPharmacyDialog } from "./modules/ECommerce/pages/managestaff/staff-details/staff-assign-pharmacy-dialog/StaffEditAssignPharmacyDialog";
import { BackgroundPayment } from "./modules/background-payment";
import { GetRole } from "../_metronic/_helpers/GlobalHelpers";

//lazy imports
const Index = lazy(() => import("./modules/Settings/Index"));
const GlobalSearch = lazy(() => import("./pages/GlobalSearch"));
const DashboardPage = lazy(() => import("./pages/DashboardPage"));
const KPIDashboardPage = lazy(() => import("./pages/KpiDashboardPage"));
const IndexCarriers = lazy(() =>
  import("./modules/SettingsCarriers/IndexCarriers")
);
const ECommercePage = lazy(() =>
  import("./modules/ECommerce/pages/eCommercePage")
);
const UserProfilepage = lazy(() =>
  import("./modules/UserProfile/UserProfilePage")
);

const CommandCenter = lazy(() =>
  import("./modules/ECommerce/pages/managecommandcenter")
);

export default function BasePage() {
  // useEffect(() => {
  //   console.log('Base page');
  // }, []) // [] - is required if you need only one call
  // https://reactjs.org/docs/hooks-reference.html#useeffect
  const [IntroSeen, setIntroSeen] = useState(null);
  useEffect(() => {
    setTimeout(() => {
      setIntroSeen(true);
    }, 1500);
  }, []);
  return (
    <>
      {IntroSeen === true ? <GuidedTour /> : ""}
      <Suspense fallback={<LayoutSplashScreen />}>
        <Switch>
       
          {
            (GetRole()  !== "KPI Dashboard Manager" && GetRole()  !== "KPI Dashboard Staff"  && GetRole() !== "Dispatcher") && <Redirect exact from="/" to="/dashboard" />
          }
          {
            GetRole() === "Dispatcher" && <Redirect exact from="/" to="/command-center" />
          }
          {
            (GetRole()  === "KPI Dashboard Manager" || GetRole()  === "KPI Dashboard Staff") && <Redirect exact from="/" to="/corporate-dashboard" />
          }
          {
            (GetRole()  === "KPI Dashboard Manager" || GetRole()  === "KPI Dashboard Staff") && <Redirect exact from="/dashboard" to="/corporate-dashboard" />
          }
          {(GetRole() !== "KPI Dashboard Manager" && GetRole() !== "KPI Dashboard Staff") && <ContentRoute path="/dashboard" component={DashboardPage} />}
          {(GetRole()  === "KPI Dashboard Manager" || GetRole()  === "KPI Dashboard Staff") && <ContentRoute path="/corporate-dashboard" component={KPIDashboardPage} />}
          <ContentRoute path="/search" component={GlobalSearch} />
          <Route path="/stores" component={ECommercePage} />
          <Route path="/carriers" component={ECommercePage} />
          {(GetRole()  !== "KPI Dashboard Manager" && GetRole()  !== "KPI Dashboard Staff") && <Route path="/customers" component={ECommercePage} />}
          <Route path="/orders" component={ECommercePage} />
          <Route path="/rxdrop" component={ECommercePage} />
          <Route path="/notifications" component={ECommercePage} />
          <Route path="/deliveryPayment" component={PaymentDelivery} />
          <Route path="/background-check/payment" component={BackgroundPayment} />
          <Route path="/business-managers" component={ECommercePage} />
          <Route path="/order/:id/details" component={ECommercePage} />
          <Route path="/customer/:id/details" component={ECommercePage} />
          <Route path="/pharmacy/:id/details" component={PharmacyDetails} />
          <Route path="/store/:id/details" component={ECommercePage} />
          <Route path="/driver/:id/details" component={ECommercePage} />
          <Route path="/carrier/:id/details" component={ECommercePage} />
          <ContentRoute path="/staff-details/:id" component={ECommercePage} />
          <ContentRoute path="/store/:id/invoices" component={ECommercePage} />
          <ContentRoute path="/manage-releases" component={ECommercePage} />
          <Route path="/user-profile" component={UserProfilepage} />
          {(GetRole() !== "KPI Dashboard Manager" && GetRole() !== "KPI Dashboard Staff" && GetRole() !== "Carrier Admin" && GetRole() !== "Dispatcher") && <Route path="/settings" component={Index} />}
          <Route path="/settings-carriers" component={IndexCarriers} />
          <Route path="/Storefront" component={ProductOTC} />
          {(GetRole() !== "KPI Dashboard Manager" && GetRole() !== "KPI Dashboard Staff" && GetRole() !== "Carrier Admin") && <Route path="/new-product" component={ProductOTCEvent} />}
          {(GetRole() === "Carrier Admin" || GetRole() === "Carrier User" || GetRole() === "Dispatcher") && <Route path="/command-center" component={CommandCenter} /> }
          <Route path="/drivers" component={ECommercePage} />
          <Route path="/qb" component={ECommercePage} />
          <Route path="/deleted-drivers" component={ECommercePage} />
          <Route path="/approve-drivers" component={ECommercePage} />
          <Route path="/approve-driver/:id/details" component={ECommercePage} />
          <Route path="/setup/twoFactor" component={ECommercePage} />
          {GetRole() !== "Dispatcher" && (
            <>
              <Route path="/stores-list" component={ECommercePage} />
              <Route path="/billing-management" component={TransactionManagement} />
              <Route path="/staff" component={ECommercePage} />
              <Route path="/help-center" component={HelpCenter} />
            </>
          )}
          <Redirect to="error/404" />
        </Switch>
      </Suspense>
    </>
  );
}
