import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";

const WalmatCallback = () => {
  const code = new URLSearchParams(window.location.search).get("code");
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [isLoading, setIsLoading] = useState(false);
  const codeVerifier = localStorage.getItem("codeVerifier") ? localStorage.getItem("codeVerifier") : "";
  const history = useHistory();
  const [showRetry, setShowRetry] = useState(false);

  const handleCallBack = (values) => {
    localStorage.setItem('isDisabledSso', true);
    setIsLoading(true);
    fetch(`${BASE_URL}/Pkce/walmart/callback`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      Allow: "POST",
      body: JSON.stringify(values),
    })
      .then(async (response) => {
        const statusCode = await response.status;
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
          return response.json().then((data) => [statusCode, data]);
        } else {
          return response.text().then((data) => [statusCode, data]);
        }
      })
      .then((data) => {
        if (data[0] === 200) {
          localStorage.removeItem("user");
          localStorage.removeItem("ProfilePicture");
          localStorage.removeItem("dateFormat");
          // localStorage.clear();
          localStorage.setItem("user", JSON.stringify(data[1]));
          localStorage.setItem(
            "useSso",
            JSON.stringify(data[1].useSso || true)
          );
          localStorage.setItem(
            "ProfilePicture",
            JSON.stringify(data[1].profilePicture)
          );
          localStorage.setItem(
            "dateFormat",
            data[1]?.dateFormat !== null &&
              data[1]?.dateFormat !== undefined &&
              data[1]?.dateFormat !== ""
              ? data[1]?.dateFormat
              : "DD/MM/YYYY"
          );
          localStorage.setItem(
            "persist:v713-demo1-auth",
            JSON.stringify({
              _persist: '{"version":-1,"rehydrated":true}',
              authToken: '""',
              user: "{}",
            })
          );
          history.push("/dashboard");
          window.location.reload();
        } else if (data[0] === 400 && data[1].message) {
          setShowRetry(true);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: data[1].message,
          });
        } else if (data[0] === 400) {
          setShowRetry(true);
          let tt = JSON.parse(data[1]);
          for (const [key, value] of Object.entries(tt.errors)) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: value,
            });
          }
        } else if (data[0] === 500) {
          setShowRetry(true);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong, please try again later!",
          });
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
        localStorage.removeItem("isDisabledSso");
      });
  };

  useEffect(() => {
    if (code) {
      handleCallBack({
        code,
        codeVerifier,
      });
    }
  }, [code, codeVerifier]);

  return (
    <div>
      {isLoading && (
        <div className="spinner spinner-primary spinner-center mt-10 h1"></div>
      )}
      {showRetry && (
        <button
          className="btn btn-info px-"
          onClick={() => {
            history.push("/auth/walmart/sso");
            localStorage.removeItem("isDisabledSso");
          }}
        >
          Retry Login
        </button>
      )}
    </div>
  );
};

export default WalmatCallback;
