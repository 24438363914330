import React, { useEffect, useState } from "react";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import SVG from "react-inlinesvg";

// "https://pfedcert.wal-mart.com/as/authorization.oauth2?client_id=staging-app-deliveryease&response_type=code&scope=openid profile email&redirect_uri=https://staging-app.deliveryease.co/walmart/callback&code_challenge_method=S256&code_challenge=_D_sLAFF5TOSg9R_h_P1gwgm91kUCaU4anbSmXETYnU"

const WalmartSSO = () => {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [isLoading, setIsLoading] = useState(false);
  const codeVerifier = localStorage.getItem("codeVerifier")
  const isDisabledSso = localStorage.getItem("isDisabledSso") ? JSON.parse(localStorage.getItem("isDisabledSso")) : false;

  const GetAuthorize = () => {
    setIsLoading(true);
    fetch(`${BASE_URL}/Pkce/walmart/authorize`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const statusCode = await response.status;
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
          return response.json().then((data) => [statusCode, data]);
        } else {
          return response.text().then((data) => [statusCode, data]);
        }
      })
      .then((data) => {
        if (data[0] === 200) {
          window.location.href = data[1].authorizeUrl;
          localStorage.setItem("codeVerifier", data[1].codeVerifier);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div>
      <button
        type="button"
        className="btn btn-light-info font-weight-bolder px-8 py-4 my-3 font-size-lg"
        disabled={isLoading || isDisabledSso}
        onClick={() => GetAuthorize()}
      >
        <span className="svg-icon svg-icon-md">
          <span
            style={{ cursor: "pointer" }}
            className={`svg-icon svg-icon-md svg-icon-info mr-1`}
          >
            <SVG src={toAbsoluteUrl("/media/icons/walmart-icon.svg")} />
          </span>
          {/*end::Svg Icon*/}
        </span>{" "}
        {(isLoading || isDisabledSso )? "Please wait..." : " Sign in with Walmart"}
      </button>
    </div>
  );
};

export default WalmartSSO;
